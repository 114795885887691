<template>
  <div class="content">
    <PageHeader :title="$t('generic-str.supp')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <form class="row" _lpchecked="1">
                <div class="col-md-4">
                  <label class="sr-only" for="inlineFormInputName2"
                    >{{$t('generic-str.search-by.default')}}</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Assunto..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      v-model="form.subject.lk"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary btn-icon"
                        type="button"
                        @click="fetch(1)"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label class="sr-only" for="status">Status</label>
                  <select
                    class="form-control"
                    id="status"
                    v-model="form.status"
                    @change="fetch(1)"
                  >
                    <option selected value>Status</option>
                    <option value="open">{{$t('generic-str.status.lbl-open')}}</option>
                    <option value="closed">{{$t('generic-str.status.lbl-closed')}}</option>
                    <option value="archived">{{$t('generic-str.status.lbl-filed')}}</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <router-link
                    to="/support/new-ticket"
                    class="btn btn-success pull-right"
                  >
                    {{$t('generic-str.lbl-new-call')}}
                  </router-link>
                </div>
              </form>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive">
                <table v-if="fetched && tickets.length" class="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>{{$t('generic-str.subject')}}</th>
                      <th>{{$t('generic-str.date')}}</th>
                      <th>{{$t('calls-component.last-interaction')}}</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ticket in tickets" :key="ticket.id">
                      <td>{{ ticket.id | minifyUuid }}</td>
                      <td>{{ ticket.subject }}</td>
                      <td>{{ ticket.created_at | formatDate24 }}</td>
                      <td>{{ ticket.last_reply.type == 'staff' ? 'Suporte' : ticket.last_reply.user.name }} ({{ ticket.updated_at | relativeDate }})</td>
                      <td>
                        <span
                          class="badge badge-info"
                          v-if="ticket.status == 'open'"
                          >{{$t('generic-str.status.lbl-open')}}</span
                        >
                        <span
                          class="badge badge-danger"
                          v-else-if="ticket.status == 'closed'"
                          >{{$t('generic-str.status.lbl-closed')}}</span
                        >
                        <span
                          class="badge badge-dark"
                          v-else-if="ticket.status == 'archived'"
                          >{{$t('generic-str.status.lbl-filed')}}</span
                        >
                      </td>
                      <td>
                        <router-link :to="`support/${ticket.id}`">
                          <i class="zmdi zmdi-comments zmdi-hc-fw"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else-if="fetched" class="text-center table-placeholder">
                  <i class="fas fa-life-ring font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('ticket-component.none')}}</h5>
                  <p>
                    <a
                      href="/support/new-ticket"
                      class="btn btn-success"
                      aria-expanded="false"
                    >
                      {{$t('generic-str.lbl-open-ticket')}}
                    </a>
                  </p>
                </div>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from '@/services/ticket.service';
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      tickets: [],
      form: {
        subject: {},
        status: '',
        sort: 'created_at:desc',
        page: 1,
      },
      pages: 1,
      exporting: false,
      isSending: false,
      opens: [],
      opensPercent: 0,
      answeredPercent: 0,
      answered: [],
    };
  },
  computed: {
    info() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      TicketService.getTickets(this.form).then(
        (response) => {
          this.fetched = true;
          this.tickets = response.data;
          this.opens = [];
          this.answered = [];
          // this.pages = response.last_page;
          if (this.tickets.length > 0) {
            this.tickets.forEach((element) => {
              if (element.status === 'open') {
                this.opens.push(element);
              }

              if (element.status === 'answered') {
                this.answered.push(element);
              }
            });
            this.opensPercent =
              (this.opens.length / this.tickets.length) * 100;
            this.answeredPercent =
              (this.answered.length / this.tickets.length) * 100;
          }
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
td a i {
  font-size: 1.19rem;
}
</style>
